import { sendNotification } from '@/services/Notification';
import { manageCandidatesTime } from '@/modules/tsheets/apiAdapters/tsheetsAdapters';

import type { TSheetsCandidate } from './tsheets.d';

import { getWeekEndingDate } from './helpers';

import {
  SUCCESS_APPROVED_TIME_MESSAGE,
  SUCCESS_REJECTED_TIME_MESSAGE,
  ERROR_APPROVE_TIME_MESSAGE,
  ERROR_REJECT_TIME_MESSAGE,
} from './constants';

export async function manageCandidateTime({
  candidates,
  note,
  isRejected,
}: {
  candidates: TSheetsCandidate[];
  note?: string;
  isRejected: boolean;
}) {
  const approveTo = getWeekEndingDate();

  try {
    const managedTime = await manageCandidatesTime(
      candidates.map(({ username }) => ({
        username,
        isRejected,
        note: note || '',
        approved_to: approveTo,
      }))
    );

    const message = managedTime[0]?.message;

    if (message) {
      sendNotification({ message });
    } else {
      sendNotification({
        message: isRejected ? SUCCESS_REJECTED_TIME_MESSAGE : SUCCESS_APPROVED_TIME_MESSAGE,
      });
    }

    return managedTime;
  } catch (e) {
    sendNotification({
      message: isRejected ? ERROR_REJECT_TIME_MESSAGE : ERROR_APPROVE_TIME_MESSAGE,
    });
  }
}
