import * as React from 'react';
import { Spin, Space } from 'antd';

type SpinnerProps = {
  isLoaded: boolean;
  size: 'small' | 'large';
  children: JSX.Element | JSX.Element[];
  prefixCls?: string;
};

export function Spinner({ isLoaded, size, children, prefixCls }: SpinnerProps) {
  if (isLoaded) return <>{children}</>;

  return (
    <Space prefixCls={prefixCls} size={size}>
      <Spin size={size} />
    </Space>
  );
}
