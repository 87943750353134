import * as React from 'react';
import { useState, useEffect } from 'react';

import { Menu } from 'antd';

import { history } from '@/application/browserHistory';
import { Logo } from '@/components/Logo';

import type { MenuListType } from './MenuList';

import { MenuList } from './MenuList';

import styles from './TopNavigation.styles.less';

type TopNavigationProps = {
  isSignedIn: boolean;
};

export function TopNavigation({ isSignedIn }: TopNavigationProps) {
  const [current, setCurrent] = useState(history.location.pathname);

  const unlisten = history.listen(({ pathname }) => {
    setCurrent(pathname);
  });

  const handleClick = (e: any) => {
    setCurrent(e.key);

    history.push(e.key);
  };

  useEffect(() => () => unlisten(), [unlisten, isSignedIn]);

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      theme="dark"
      mode="horizontal"
      style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
    >
      <Menu.Item className={styles.logo}>
        <Logo type="white" />
      </Menu.Item>
      {MenuList.map(
        ({ link, title, Icon, isPrivate, onClick }: MenuListType) =>
          isSignedIn === isPrivate && (
            <Menu.Item
              key={link}
              icon={<Icon />}
              onClick={onClick}
              style={{ fontSize: '14px', padding: '10px 20px' }}
            >
              {title}
            </Menu.Item>
          )
      )}
    </Menu>
  );
}
