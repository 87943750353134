import React from 'react';

import styles from './MainBackground.styles.less';

type MainBackgroundProps = {
  children: JSX.Element | JSX.Element[];
};

export function MainBackground({ children }: MainBackgroundProps) {
  return (
    <div className={styles.parent}>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
