import * as React from 'react';
import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { config } from '@/config/firebase';
import { PRIVATE_BASE_PATH } from '@/config/routes';

import { MainBackground } from '../../components/MainBackground';

firebase.initializeApp(config);

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: PRIVATE_BASE_PATH,
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

export const SignInScene = () => {
  return (
    <MainBackground>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </MainBackground>
  );
};
