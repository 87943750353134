import axios from 'axios';
import firebase from 'firebase/app';

export type { AxiosResponse } from 'axios';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

apiClient.interceptors.response.use(({ data }) => data);

apiClient.interceptors.request.use(
  async (config) => {
    const currentUser = await firebase.auth().currentUser;

    if (currentUser !== null) {
      config.headers.token = await currentUser.getIdToken();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  }
}
