import { Dispatch, useEffect, useState } from 'react';

import { getOAuthURL } from '../apiAdapters/qboAdapters';

export type useOauthURLHookType = [string, Dispatch<string>];

export function useOauthURLHook(): useOauthURLHookType {
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const asyncGetUrl = async () => {
      try {
        const { url } = await getOAuthURL();

        setUrl(url);
      } catch (e) {
        console.log('QBO Auth URL', e.response.data.message);
      }
    };

    if (!url) {
      asyncGetUrl();
    }
  }, [url]);

  return [url, setUrl];
}
