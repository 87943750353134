import * as React from 'react';
import { useEffect } from 'react';

import { PRIVATE_BASE_PATH } from '@/config/routes';
import { history } from '@/application/browserHistory';
import { QBO_MESSAGE, SUCCESSFUL_REDIRECT, FAILURE_REDIRECT } from '@/config/notifications/qbo';

import type { NotificationsType } from '@/services/Notification';
import { sendNotification } from '@/services/Notification';

import { setQBOAuthToken } from '../../apiAdapters/qboAdapters';

const sendQBONotification = (description: string, type: NotificationsType) =>
  sendNotification({ description, message: QBO_MESSAGE }, { type });

export function QBORedirect() {
  useEffect(() => {
    const asyncCall = async () => {
      const res = await setQBOAuthToken(history.location.search);

      res.refreshToken
        ? sendQBONotification(SUCCESSFUL_REDIRECT, 'success')
        : sendQBONotification(FAILURE_REDIRECT, 'error');

      console.log(res);

      return history.push(PRIVATE_BASE_PATH);
    };

    asyncCall();
  }, []);

  return <h1>Redirect QBO Page</h1>;
}
