import * as React from 'react';

import { Table, Space } from 'antd';
import { useContext } from 'react';

import type { TSheetsCandidate } from '@/modules/tsheets';

import { getTotalTime, isValidValue } from '@/modules/tsheets/helpers';
import { CandidatesContext } from '@/modules/tsheets/context/candidates';

import { TimesTable } from '@/modules/tsheets/components/TimesTable';
import { RejectButton } from '@/modules/tsheets/components/RejectButton';
import { ApproveButton } from '@/modules/tsheets/components/ApproveButton';

import { ApproveAll } from '../ApproveAll';

type CandidatesTableProps = {
  isLoading: boolean;
};

const getColumns = (
  setCandidate: (candidate: TSheetsCandidate, isApproved: boolean) => any,
  unverifiedCandidates: TSheetsCandidate[]
) => [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
  },
  {
    title: 'Total Time',
    dataIndex: 'time',
    key: 'total_time',
    render: getTotalTime,
  },
  {
    title: () => unverifiedCandidates.length > 0 && <ApproveAll />,
    dataIndex: 'isApproved',
    key: 'isApproved',
    render: (isApproved: boolean | null, candidate: TSheetsCandidate) => {
      return (
        <div>
          <Space>
            {typeof isApproved !== 'boolean' && (
              <ApproveButton candidate={candidate} setCandidate={setCandidate} />
            )}
            {typeof isApproved !== 'boolean' && (
              <RejectButton candidate={candidate} setCandidate={setCandidate} />
            )}
          </Space>
          <p>
            {typeof isApproved === 'boolean' && (
              <p>{`Time is ${isApproved ? 'approved' : 'rejected'}`}</p>
            )}
          </p>
        </div>
      );
    },
  },
];

export function CandidatesTable({ isLoading }: CandidatesTableProps) {
  const { candidates, setCandidates } = useContext(CandidatesContext);
  const setCandidate = (incomingCandidate: TSheetsCandidate, isApproved: boolean) => {
    const updatedCandidates = candidates.map((candidate) => {
      if (candidate.email === incomingCandidate.email) {
        return { ...candidate, isApproved };
      }

      return candidate;
    });

    if (setCandidates) {
      setCandidates(updatedCandidates);
    }
  };

  const unverifiedCandidates = candidates.filter(({ isApproved }) => !isValidValue(isApproved));

  return (
    <Table
      style={{ width: '75%', margin: 'auto' }}
      pagination={false}
      loading={isLoading}
      dataSource={candidates}
      rowKey={(record) => record.id}
      columns={getColumns(setCandidate, unverifiedCandidates)}
      expandable={{
        expandedRowRender: (candidate) => <TimesTable data={candidate.time} />,
      }}
    />
  );
}
