import { apiClient } from '@/services/API';

import type { TSheetsCandidate } from '@/modules/tsheets';

export const BASE_URL = '/api/tsheets';

export type TSheetsTime = {
  id: number;
  user_id: number;
  start: string;
  end: string;
  duration: string;
  date: string;
  notes: string;
};

export type TSheetsApproveCandidatesTime = {
  username: string;
  approved_to: string;
  isRejected: boolean;
  note: string;
};

export type TimeReviews = {
  isApproved: boolean;
  note: string | null;
  reviewId: number;
  reviewedBy: number;
  reviewedOn: string;
  timeOf: number;
  weekEnding: string;
  message?: string;
};

export const getCandidates = () => apiClient.get<TSheetsCandidate[]>(`${BASE_URL}/candidates`);

export const manageCandidatesTime = (candidatesTime: TSheetsApproveCandidatesTime[]) =>
  apiClient.post<TimeReviews[]>(`${BASE_URL}/candidates/manage-time`, candidatesTime);
