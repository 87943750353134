import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { PUBLIC_BASE_PATH } from '@/config/routes';

type PrivateRouteProps = {
  component: (rest: any) => JSX.Element;
  isSignedIn: boolean;
  [key: string]: any;
};

export const PrivateRoute = ({ component: Component, isSignedIn, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      isSignedIn ? <Component {...props} /> : <Redirect from={rest.path} to={PUBLIC_BASE_PATH} />
    }
  />
);
