import { Dispatch, useEffect, useState } from 'react';

import type { TSheetsCandidate } from '@/modules/tsheets';
import { sendNotification } from '@/services/Notification';

import { getCandidates } from '../apiAdapters/tsheetsAdapters';

export type useGetCandidatesHookType = [TSheetsCandidate[], boolean, Dispatch<TSheetsCandidate[]>];

export function useGetCandidatesHook(): useGetCandidatesHookType {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<TSheetsCandidate[] | never[]>([]);

  useEffect(() => {
    const asyncGetCandidates = async () => {
      try {
        setIsLoading(true);
        const tSheetsCandidates = await getCandidates();

        setCandidates(tSheetsCandidates);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);

        sendNotification({
          message: 'Candidates',
          description: 'Something went wrong while fetching candidates',
        });
      }
    };

    if (!candidates.length && !isLoading) {
      asyncGetCandidates();
    }
    // eslint-disable-next-line
  }, [candidates]);

  return [candidates, isLoading, setCandidates];
}
