import { SmileOutlined, LogoutOutlined, ClockCircleOutlined } from '@ant-design/icons';

import { BaseProvider } from '@/services/Firebase/BaseProvider';

import { SIGH_IN_PATH, SIGH_OUT_PATH, CANDIDATES_PATH } from '@/routes';

const baseProvider = new BaseProvider();

export type MenuListType = {
  link: string;
  title: string;
  Icon: any;
  isPrivate?: boolean;
  onClick?: () => any;
};

export const MenuList: MenuListType[] = [
  {
    link: SIGH_IN_PATH,
    title: 'Sign In',
    Icon: SmileOutlined,
    isPrivate: false,
  },
  {
    link: CANDIDATES_PATH,
    title: 'Candidates',
    Icon: ClockCircleOutlined,
    isPrivate: true,
  },
  {
    link: SIGH_OUT_PATH,
    title: 'Log Out',
    Icon: LogoutOutlined,
    isPrivate: true,
    onClick: baseProvider.signOut,
  },
];
