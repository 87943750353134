import * as React from 'react';

import { BaseResult } from '../BaseResult';

export const STATUS_NUMBER = 500;
export const SUB_TITLE = 'Sorry, something went wrong.';

export function InternalError() {
  return <BaseResult status={STATUS_NUMBER} subTitle={SUB_TITLE} />;
}
