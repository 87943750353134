import * as React from 'react';
import { useEffect } from 'react';

import { AuthService } from '@/modules/auth';
import { history } from '@/application/browserHistory';
import { CANDIDATES_PATH, NOT_AUTHORIZED_PATH, INTERNAL_ERROR_PATH } from '@/routes';

const authService = new AuthService();

type InitProps = {
  user: any;
};

export function Init({ user }: InitProps) {
  useEffect(() => {
    const asyncCall = async () => {
      try {
        await authService.getUser();

        if ([NOT_AUTHORIZED_PATH, INTERNAL_ERROR_PATH].includes(history.location.pathname)) {
          history.push(CANDIDATES_PATH);
        }
      } catch (e) {
        if (e.response?.status === 401) {
          return history.push(NOT_AUTHORIZED_PATH);
        }

        return history.push(INTERNAL_ERROR_PATH);
      }
    };

    if (user) {
      asyncCall();
    }
  });

  return <></>;
}
