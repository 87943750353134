import React from 'react';

export const WHITE_LOGO_PUBLIC = '/images/All_WhiteType_1200.png';

export const BLACK_LOGO_PUBLIC = '/images/black_800_RJ.png';

type LogoProps = {
  type: 'black' | 'white';
};

const logoTypeAccess = {
  black: BLACK_LOGO_PUBLIC,
  white: WHITE_LOGO_PUBLIC,
};

export function Logo({ type }: LogoProps) {
  return <img src={logoTypeAccess[type]} alt="RoesselJoyLogo" />;
}
