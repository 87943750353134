import type { InitializeAppArgs } from '@react-firebase/auth/dist/types';

export interface ProcessEnv {
  [key: string]: string | undefined;
}

const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_MEASUREMENT_ID,
  REACT_APP_APP_ID,
}: ProcessEnv = process.env;

export const config: InitializeAppArgs = {
  firebase: undefined,
  apiKey: REACT_APP_API_KEY || '',
  authDomain: REACT_APP_AUTH_DOMAIN || '',
  databaseURL: REACT_APP_DATABASE_URL || '',
  projectId: REACT_APP_PROJECT_ID || '',
  storageBucket: REACT_APP_STORAGE_BUCKET || '',
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID || '',
  appId: REACT_APP_APP_ID || '',
  measurementId: REACT_APP_MEASUREMENT_ID || '',
};
