import { getFullUser } from '../apiAdapters/users';

export class AuthService {
  async getUser() {
    const user = await getFullUser();

    console.log('USER', user);

    return user;
  }
}
