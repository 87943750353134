import * as React from 'react';
import { Layout } from 'antd';

import styles from './Footer.styles.less';

type FooterProps = {
  children: JSX.Element | JSX.Element[];
};

export function Footer({ children }: FooterProps) {
  return (
    <Layout.Footer className={styles.parent}>
      <p>© 2020 Roessel Joy, LLC | 10 High Street, Suite 605 | Boston, MA 02110</p>
      {children}
    </Layout.Footer>
  );
}
