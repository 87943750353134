import { notification } from 'antd';

export type PlacementType = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft';
export type NotificationsType = 'success' | 'info' | 'warning' | 'error' | 'open';

export const sendNotification = (
  { message, description }: { message: string; description?: string },
  { placement, type }: { placement?: PlacementType; type?: NotificationsType } = {
    placement: 'topRight',
  }
) => {
  notification[type || 'success']({
    message,
    placement,
    description,
  });
};
