import * as React from 'react';
import { useContext, useCallback } from 'react';

import { Button, Popconfirm } from 'antd';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { manageCandidateTime } from '@/modules/tsheets';
import { CandidatesContext } from '@/modules/tsheets/context/candidates';

export function ApproveAll() {
  const { candidates, setCandidates } = useContext(CandidatesContext);

  const approveCandidatesTime = useCallback(async () => {
    await manageCandidateTime({ candidates, isRejected: false });

    if (setCandidates) {
      setCandidates(candidates.map((candidate) => ({ ...candidate, isApproved: true })));
    }
  }, [candidates, setCandidates]);

  return (
    <Popconfirm
      title="Are you sure"
      onConfirm={approveCandidatesTime}
      icon={<QuestionCircleOutlined />}
    >
      <Button type="primary" icon={<CheckOutlined />}>
        Approve All
      </Button>
    </Popconfirm>
  );
}
