import * as React from 'react';

import { BaseResult } from '../BaseResult';

export const STATUS_NUMBER = 403;
export const SUB_TITLE = 'Sorry, you are not authorized to access this page.';

export function NotAuthorized() {
  return <BaseResult status={STATUS_NUMBER} subTitle={SUB_TITLE} />;
}
