import * as React from 'react';

import { useOauthURLHook } from '../../hooks/useOauthURLHook';

export const QBO_LOGO_PUBLIC = '/images/QBLogoAvatar2x.png';

export type QBOCheckProps = {
  isSignedIn: boolean;
};

export function QBOCheck({ isSignedIn }: QBOCheckProps) {
  const [url] = useOauthURLHook();

  if (!url || !isSignedIn) return <></>;

  return (
    <a href={url}>
      <img src={QBO_LOGO_PUBLIC} alt="QBO" />
    </a>
  );
}
