import { createContext, Dispatch } from 'react';
import type { TSheetsCandidate } from '@/modules/tsheets';

type CandidatesContextType = {
  candidates: TSheetsCandidate[];
  setCandidates?: Dispatch<TSheetsCandidate[]>;
};

export const CandidatesContext = createContext<CandidatesContextType>({
  candidates: [],
});
