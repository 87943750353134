import * as React from 'react';
import { useState, useCallback } from 'react';

import { Button, Modal, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import type { TSheetsCandidate } from '../../tsheets';
import { isApprovedCandidate, isValidValue } from '../../helpers';
import { useRejectCandidateTimeHook } from '../../hooks/useRejectCandidateTimeHook';

const { TextArea } = Input;

export type RejectButtonProps = {
  candidate: TSheetsCandidate;
  setCandidate: (candidate: TSheetsCandidate, isApproved: boolean) => any;
};

export function RejectButton({ candidate, setCandidate }: RejectButtonProps) {
  const [isVisible, setIsVisible] = useState(false);

  const [rejectCandidateTimeFn, note, setNote] = useRejectCandidateTimeHook(candidate);
  const rejectCandidateTime = useCallback(rejectCandidateTimeFn, [
    note,
    candidate,
    rejectCandidateTimeFn,
  ]);

  const { isApproved } = candidate;

  const customIsApproved = isApprovedCandidate(isApproved);
  const customIsValidValue = isValidValue(isApproved);

  return (
    <>
      <Button danger type="primary" icon={<CloseOutlined />} onClick={() => setIsVisible(true)}>
        Reject
      </Button>
      <Modal
        title="Confirmation"
        visible={isVisible}
        onOk={async () => {
          setIsVisible(false);

          if (!customIsValidValue) {
            await rejectCandidateTime();
            setCandidate(candidate, false);
          }
        }}
        onCancel={() => setIsVisible(false)}
      >
        {!customIsValidValue ? (
          <>
            <p>Please provide reason for rejection.</p>
            <TextArea allowClear name="Note" onChange={(e) => setNote(String(e.target.value))} />
          </>
        ) : (
          <p>Time of this user were already {customIsApproved ? 'approved' : 'rejected'}</p>
        )}
      </Modal>
    </>
  );
}
