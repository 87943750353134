import * as React from 'react';
import { Router, Switch } from 'react-router-dom';

import 'firebase/auth';
import firebase from 'firebase/app';
import { FirebaseAuthProvider, FirebaseAuthConsumer } from '@react-firebase/auth';

import { config } from '@/config/firebase';

import { Routes } from '@/routes';
import { history } from '@/application/browserHistory';

import { Logo } from '@/components/Logo';
import { Footer } from '@/components/Footer';
import { Spinner } from '@/components/Spinner';

import { QBOCheck } from '@/modules/qbo';
import { TopNavigation } from '@/modules/navigation';

import styles from '@/Root.less';

import { Init } from './Init';

function Root() {
  // Loading state is depend from renders count of current component. So, Be careful about it
  let isLoaded = false;
  let loadedTimes = 0;

  return (
    <Router history={history}>
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <div className={styles.App}>
          <FirebaseAuthConsumer>
            {({ isSignedIn, user }) => {
              // I've figure out that when callback invoked second time, it's mean that Firebase loaded user info
              if (loadedTimes < 1) {
                loadedTimes += 1;
              } else {
                isLoaded = true;
              }

              return (
                <Spinner prefixCls={styles.spinner} isLoaded={isLoaded} size="large">
                  <div className={styles.wrapper}>
                    <Init user={user} />
                    <TopNavigation isSignedIn={isSignedIn} />
                    <Switch>
                      <Routes isSignedIn={isSignedIn} />
                    </Switch>
                  </div>
                  <Footer>
                    <div className={styles.logo}>
                      <Logo type="black" />
                    </div>
                    <div className={styles.qboLogo}>
                      <QBOCheck isSignedIn={isSignedIn} />
                    </div>
                  </Footer>
                </Spinner>
              );
            }}
          </FirebaseAuthConsumer>
        </div>
      </FirebaseAuthProvider>
    </Router>
  );
}

export default Root;
