import * as React from 'react';

import { QBORedirect } from '@/modules/qbo';
import { SignInScene } from '@/modules/auth';

import { CandidatesScene } from '@/modules/tsheets';
import { PublicRoute } from '@/components/PublicRoute';
import { PrivateRoute } from '@/components/PrivateRoute';
import { NotAuthorized, InternalError } from '@/components/Results';

import {
  SIGH_IN_PATH,
  CANDIDATES_PATH,
  QBO_REDIRECT_PATH,
  INTERNAL_ERROR_PATH,
  NOT_AUTHORIZED_PATH,
} from './paths';

const RoutesList = [
  {
    id: 'SignId',
    path: SIGH_IN_PATH,
    component: SignInScene,
    isPrivate: false,
  },
  {
    id: 'Candidates',
    path: CANDIDATES_PATH,
    component: CandidatesScene,
    isPrivate: true,
  },
  {
    id: 'QBORedirect',
    path: QBO_REDIRECT_PATH,
    component: QBORedirect,
    isPrivate: true,
  },
  {
    id: 'NotAuthorized',
    path: NOT_AUTHORIZED_PATH,
    component: NotAuthorized,
    isPrivate: true,
  },
  {
    id: 'InternalError',
    path: INTERNAL_ERROR_PATH,
    component: InternalError,
    isPrivate: true,
  },
];

type RoutesProps = {
  isSignedIn: boolean;
};

export function Routes({ isSignedIn }: RoutesProps) {
  return (
    <>
      {RoutesList.map(({ id, path, component, isPrivate }) =>
        isPrivate ? (
          <PrivateRoute exact key={id} path={path} component={component} isSignedIn={isSignedIn} />
        ) : (
          <PublicRoute exact key={id} path={path} component={component} isSignedIn={isSignedIn} />
        )
      )}
    </>
  );
}
