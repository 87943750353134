import * as React from 'react';

import { CandidatesContext } from '../context/candidates';
import { useGetCandidatesHook } from '../hooks/useGetCandidatesHook';

import { WeekEnding } from '../components/WeekEnding';
import { CandidatesTable } from '../components/CandidatesTable';

export function CandidatesScene() {
  const [candidates, isLoading, setCandidates] = useGetCandidatesHook();

  return (
    <div>
      <CandidatesContext.Provider value={{ candidates, setCandidates }}>
        <h1>Candidates</h1>
        <WeekEnding />
        <CandidatesTable isLoading={isLoading} />
      </CandidatesContext.Provider>
    </div>
  );
}
