import { Dispatch, useState } from 'react';

import type { TSheetsCandidate } from '../tsheets';

import { manageCandidateTime } from '@/modules/tsheets/tsheets.service';

export type useRejectCandidateTimeHookType = [() => void, string, Dispatch<string>];

export function useRejectCandidateTimeHook(
  candidate: TSheetsCandidate
): useRejectCandidateTimeHookType {
  const [note, setNote] = useState('');

  return [
    () => manageCandidateTime({ candidates: [candidate], note, isRejected: true }),
    note,
    setNote,
  ];
}
