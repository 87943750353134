import moment from 'moment';

export function durationToHoursView(duration: number) {
  const time = moment.duration(duration, 'seconds');

  return `${time.hours() + time.days() * 24}:${time.minutes()} h`;
}

export function getTotalTime(time: { duration: number }[]) {
  return durationToHoursView(time.reduce((totalTime, { duration }) => totalTime + duration, 0));
}

export function isValidValue(value: any) {
  return value !== null;
}

export function isApprovedCandidate(isApproved: boolean | null) {
  return isValidValue(isApproved) && isApproved !== false;
}

export function isRejectedCandidate(isApproved: boolean | null) {
  return isValidValue(isApproved) && isApproved !== true;
}

export function getWeekEndingDate() {
  return moment().endOf('isoWeek').isoWeekday(7).subtract(1, 'week').format('YYYY-MM-DD');
}
