import { apiClient } from '@/services/API';

export const BASE_URL = '/api/qbo';

export type getOAuthURLType = {
  url: string;
};

export type setQBOAuthTokenType = {
  keyId: number;
  applicationId: number;
  integrationId: number;
  otherInfo: string;
  token: string;
  tokenLastUpdated: string;
  refreshToken: string;
  refreshLastUpdated: string;
};

export const getOAuthURL = () => apiClient.get<getOAuthURLType>(`${BASE_URL}/get-oauth-url`);

export const setQBOAuthToken = (qboUrl: string) =>
  apiClient.post<setQBOAuthTokenType>(`${BASE_URL}/auth-token`, { qboUrl });
