import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { PRIVATE_BASE_PATH } from '@/config/routes';

type PublicRouteProps = {
  component: (rest: any) => JSX.Element;
  isSignedIn: boolean;
  [key: string]: any;
};

export const PublicRoute = ({ component: Component, isSignedIn, ...rest }: PublicRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      isSignedIn ? <Redirect to={PRIVATE_BASE_PATH} /> : <Component {...props} />
    }
  />
);
