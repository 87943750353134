import * as React from 'react';
import { useCallback } from 'react';

import { Popconfirm, Button } from 'antd';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import type { TSheetsCandidate } from '@/modules/tsheets';

import { manageCandidateTime } from '@/modules/tsheets';

export type ApproveButtonProps = {
  candidate: TSheetsCandidate;
  setCandidate: (candidate: TSheetsCandidate, isApproved: boolean) => any;
};

export function ApproveButton({ candidate, setCandidate }: ApproveButtonProps) {
  const approveCandidateTime = useCallback(async () => {
    await manageCandidateTime({ candidates: [candidate], isRejected: false });

    setCandidate(candidate, true);
  }, [candidate, setCandidate]);

  return (
    <Popconfirm
      title="Are you sure"
      onConfirm={approveCandidateTime}
      icon={<QuestionCircleOutlined />}
    >
      <Button type="primary" icon={<CheckOutlined />}>
        Approve
      </Button>
    </Popconfirm>
  );
}
