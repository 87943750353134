import * as React from 'react';
import { Table } from 'antd';
import moment from 'moment';

import { durationToHoursView } from '@/modules/tsheets/helpers';

import type { TSheetsTime } from '../../apiAdapters/tsheetsAdapters';

interface TimesTableProps {
  data: TSheetsTime[];
}

const columns = [
  {
    title: 'Start',
    dataIndex: 'start',
    key: 'start',
    render: (startDate: string) => moment(startDate).format('LLLL'),
  },
  {
    title: 'End',
    dataIndex: 'end',
    key: 'end',
    render: (endDate: string) => moment(endDate).format('LLLL'),
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    render: durationToHoursView,
  },
  {
    title: 'Notes',
    dataIndex: 'Notes',
    key: 'Notes',
    render: (note: string) => note || <span>No data</span>,
  },
];

export function TimesTable({ data }: TimesTableProps) {
  return (
    <Table columns={columns} dataSource={data} pagination={false} rowKey={(record) => record.id} />
  );
}
