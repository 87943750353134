import firebase from 'firebase/app';

import { history } from '@/application/browserHistory';
import { PUBLIC_BASE_PATH } from '@/config/routes';

export class BaseProvider {
  async signOut() {
    await firebase.auth().signOut();

    history.push(PUBLIC_BASE_PATH);
  }
}
