import * as React from 'react';
import { Result } from 'antd';

interface BaseResultProps {
  status: 403 | 404 | 500 | '403' | '404' | '500';
  subTitle: string;
}

export function BaseResult({ status, subTitle }: BaseResultProps) {
  return <Result status={status} subTitle={status} title={subTitle} />;
}
